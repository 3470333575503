import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { toggleSidebar } from '../../store';
import { Container, Menu, Icon } from 'semantic-ui-react';
import { MenuProps } from '../Menu';
import { Link } from 'gatsby';
import styled from 'styled-components';

interface HeaderMenuProps extends MenuProps {
  dispatch?: Dispatch<any>;
  inverted?: boolean;
}

const MenuLink = styled(Link)`
  //background-color: purple !important;
`;

export const HeaderMenu = ({
  dispatch,
  inverted,
  items,
  pathname,
}: HeaderMenuProps) => (
  <Container id={`menu-container`} className={`hovering`} style={{visibility: 'hidden'}}>
    <Menu size="small" pointing secondary inverted={inverted}>
      <Menu.Item
        as="a"
        className="mobile only"
        icon="sidebar"
        onClick={() => dispatch && dispatch(toggleSidebar())}
      />
      {/*<Menu.Item className="mobile hidden"><Icon name="spy" size="big" /></Menu.Item>*/}
      {items.map((item) => {
        const active = item.exact
          ? pathname === item.path
          : pathname.startsWith(item.path);
        return (
          <Menu.Item
            as={MenuLink}
            className="mobile hidden"
            name={item.name}
            to={item.path}
            key={item.path}
            active={active}
          />
        );
      })}
    </Menu>
  </Container>
);

export default connect()(HeaderMenu);
